import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default function About({ data }) {
    return (
        <Layout slug="/about/" {...data.meta.nodes[0]}>
            <div class="p-section">

                <section>
                    <div class="c-about-copy">
                        <h1><mark>歯科衛生士の魅力を伝える<br class="is-br-sp-show" />情報マガジン</mark></h1>
                    </div>

                    <p>めざせ！歯科衛生士は、歯科衛生士になりたいと思っている学生・社会人や、歯科衛生士について知りたい方に、歯科衛生士の魅力を存分に伝える情報マガジンです。</p>
                    <p>歯科衛生士になるにはどうしたらよいかや、仕事内容、、学校情報、学生や先生へのインタビューなど、歯科衛生士に関する情報が満載です。</p>
                    <p>このサイトを見て、一人でも多く歯科衛生士を目指す方が増えたらと願っております。</p>
                    <p><b>運営会社：</b><br />
                    　株式会社メドレー<br />
                    　<a href="https://www.medley.jp/" target="_blank">www.medley.jp</a></p>
                    <p><b>運営サイト</b><br />
                    　新卒歯科衛生士求人：<a href="https://job.guppy.jp/dh" target="_blank">job.guppy.jp/dh</a><br />
                    　中途歯科衛生士求人：<a href="https://www.guppy.jp/dh" target="_blank">www.guppy.jp/dh</a><br />
                    　国家試験アプリ：<a href="https://kokushi.guppy.jp/" target="_blank">kokushi.guppy.jp</a></p>
                </section>

            </div>
        </Layout>
    )
}

export const query = graphql`
    query {
        meta: allMeta(filter: {slug: {eq: "/about/"}}) {
            nodes {
                title
                description
                keywords
            }
        }
    }
`